.ant-table-cell {
    background: #2D2C39;
    color: white;
}

.ant-table-content {
    background: #3763F4 !important;
    color: white;
}

.ant-table-cell-row-hover {
    background: rgba(45, 44, 57, 0.3) !important;
    color: white !important;
}

.table-res {
    max-width: 80%;
    margin: 120px auto;

}
@media (max-width: 768px) {

    .table-res {
        max-width: 95%;
    }
}
.ant-table-thead>tr>th{
    background: #3763F4 !important;
    color: white !important;
}
.fixed-top{
    z-index: 10!important;
}
label{
    font-weight: bold;
}
.ant-form-item-label{
    max-width: 100%!important;
}
.ant-col-14 {
    max-width: 100%!important;
}
.navbar{
    box-shadow: #171717 10px 10px 10px !important;
}
.form-control{
    background: white !important;
    color: black !important;
}